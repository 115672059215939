body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body.unityelds {
  font-family: "Arial";
}

.date_full .react-datepicker-wrapper,
.date_full .react-datepicker-wrapper * {
  width: 100%;
}

.date_full .react-datepicker--time-only .react-datepicker__time-container,
.date_full
  .react-datepicker--time-only
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100px;
}

.date_full
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 15px;
  padding: 5px 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.leaflet-div-icon {
  background: none;
  border: none;
}

a.leaflet-popup-close-button {
  padding: 8px !important;
  margin: 1px 10px !important;
  color: black !important;
}

.multi-edit-date .MuiFormControl-root {
  width: 100%;
}

#verify .MuiTableCell-body {
  font-size: 11px;
  padding: 2px;
}

.no-data {
  color: red;
}

/* .MuiButton-containedPrimary {
  background-color: #009be5 !important;
  color: white !important;
}

.MuiButton-textPrimary {
  color: #009be5 !important;
}

.MuiButton-outlinedPrimary {
  color: #009be5 !important;
  border: 1px solid rgba(0, 155, 229, 0.5) !important;
}

.MuiButton-contained.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12) !important;
} */

/*
Duty status chart - tooltip
*/
#dutyStatusGridTooltip .tooltiptext {
  background-color: black;
  color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  padding-left: 15px;
  height: 100px;
  width: 250px;
  text-align: left;
  font-size: 12px;
  text-transform: capitalize;
}

#dutyStatusGridTooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
