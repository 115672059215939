.time-circle {
    position: relative;
    float: left;
}


.time-circle h2 {
    text-align:center;
    margin:auto;
    margin-top:10px;
    position: absolute;
    line-height: 125px;
    width: 100%;
}
.time-circle h3 {
    text-align:center;
    position: absolute;
    line-height: 125px;
    width: 100%;
    margin-top: 30px;
}

.svg svg{
    transform: rotate(-270deg);
}
