#app-bluehorse .MuiPaper-rounded,
#app-bluehorse .MuiButtonBase-root {
  border-radius: 0px;
}

#app-bluehorse table tr {
  border: 2px solid #b3b3b3;
  border-radius: 0px;
}

#app-bluehorse .MuiSvgIcon-root {
  color: #253b98;
}
